import Vue from 'vue'
import VueRouter from 'vue-router'
import {hasCookie, getCookie} from './utils/cookies';
import store from './store'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/login',
  },
  {
    path: '/donation-types',
    name: 'donation-types',
    component: () => import('@/views/donation-type/Index.vue'),
    meta: {
      requiresAuth: true,
      requiredRoles: ['admin'],
    },
  },
  {
    path: '/festivals',
    name: 'festivals',
    component: () => import('@/views/festival/Index.vue'),
    meta: {
      requiresAuth: true,
      requiredRoles: ['admin'],
    },
  },
  {
    path: '/temples',
    name: 'temples',
    component: () => import('@/views/temple/Index.vue'),
    meta: {
      requiresAuth: true,
      requiredRoles: ['admin'],
    },
  },
  {
    path: '/temples/add',
    name: 'add-temple',
    component: () => import('@/views/temple/Form.vue'),
    meta: {
      requiresAuth: true,
      requiredRoles: ['admin'],
    },
  },
  {
    path: '/temples/:id',
    name: 'edit-temple',
    component: () => import('@/views/temple/Form.vue'),
    meta: {
      requiresAuth: true,
      requiredRoles: ['admin'],
    },
  },
  {
    path: '/celebrations',
    name: 'celebrations',
    component: () => import('@/views/celebration/Index.vue'),
    meta: {
      requiresAuth: true,
      requiredRoles: ['admin', 'kothari'],
    },
  },
  {
    path: '/celebrations/add',
    name: 'add-celebration',
    component: () => import('@/views/celebration/Form.vue'),
    meta: {
      requiresAuth: true,
      requiredRoles: ['admin', 'kothari'],
    },
  },
  {
    path: '/celebrations/:id',
    name: 'edit-celebration',
    component: () => import('@/views/celebration/Form.vue'),
    meta: {
      requiresAuth: true,
      requiredRoles: ['admin', 'kothari'],
    },
  },
  {
    path: '/donations',
    name: 'donations',
    component: () => import('@/views/donation/Index.vue'),
    meta: {
      requiresAuth: true,
      requiredRoles: ['admin', 'kothari', 'cashier'],
    },
  },
  {
    path: '/donations/new/:type',
    name: 'add-donation',
    component: () => import('@/views/donation/Form.vue'),
    meta: {
      requiresAuth: true,
      requiredRoles: ['admin', 'kothari', 'cashier'],
    },
  },
  {
    path: '/donations/:id',
    name: 'edit-donation',
    component: () => import('@/views/donation/Form.vue'),
    meta: {
      requiresAuth: true,
      requiredRoles: ['admin', 'kothari', 'cashier'],
    },
  },
  {
    path: '/installments/new/:id',
    name: 'add-installment',
    component: () => import('@/views/donation/Form.vue'),
    meta: {
      requiresAuth: true,
      requiredRoles: ['admin', 'kothari', 'cashier'],
    },
  },
  {
    path: '/installments/:id',
    name: 'edit-installment',
    component: () => import('@/views/donation/Form.vue'),
    meta: {
      requiresAuth: true,
      requiredRoles: ['admin', 'kothari', 'cashier'],
    },
  },
  {
    path: '/donors',
    name: 'donors',
    component: () => import('@/views/donor/Index'),
    meta: {
      requiresAuth: true,
      requiredRoles: ['admin', 'kothari', 'cashier'],
    },
  },
  {
    path: '/reports',
    name: 'reports',
    component: () => import('@/views/reports/Index.vue'),
  },
  {
    path: '/change-password',
    name: 'change-password',
    component: () => import('@/views/settings/ChangePassword.vue'),
    meta: {
      requiresAuth: true,
      requiredRoles: ['admin', 'kothari', 'cashier'],
    },
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/Login.vue'),
    meta: {
      layout: 'blank',
      requiresGuest: true,
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('@/views/Error.vue'),
    meta: {
      layout: 'blank',
    },
  },
  {
    path: '*',
    redirect: 'error-404',
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach(async (to, from, next) => {
  let isAuthenticated = store.state.me !== null;
  if (!isAuthenticated && hasCookie('auth-token') && hasCookie('auth-token')) {
    axiosBackend.defaults.headers.Authorization = 'Bearer ' + getCookie('auth-token');
    let userData = getCookie('auth-user');
    if (userData) {
      store.commit('setLoginUser', JSON.parse(userData));
      isAuthenticated = true;
    }
  }

  if (to.meta.requiresAuth) {
    if (!isAuthenticated) {
      next('/login');
    } else if (!to.meta.requiredRoles.includes(store.state.me.role)) {
      next('/donations');
    }
  } else if (to.meta.requiresGuest) {
    if (isAuthenticated) next('/donations');
  }
  next();
})

export default router
