<template>
  <v-navigation-drawer
    :value="isDrawerOpen"
    app
    floating
    width="260"
    class="app-navigation-menu"
    :right="$vuetify.rtl"
    @input="val => $emit('update:is-drawer-open', val)"
  >
    <!-- Navigation Header -->
    <div class="vertical-nav-header d-flex items-center ps-6 pe-5 pt-5 pb-2">
      <router-link :to="{path: '/'}" class="d-flex align-center text-decoration-none">
        <v-img
          :src="require('@/assets/images/logo.png')"
          alt="logo"
          max-width="130px"
          contain
          eager
          class="app-logo me-3"
        ></v-img>
      </router-link>
    </div>

    <!-- Navigation Items -->
    <v-list expand shaped class="vertical-nav-menu-items pr-5">
      <nav-menu-link
        v-for="(navItem, key) in navigationMenu"
        v-show="!navItem.requiredRole || !navItem.requiredRole.length || ($store.state.me && navItem.requiredRole.includes($store.state.me.role))"
        :key="key"
        :title="navItem.title"
        :to="navItem.route"
        @click="handleClick(navItem.method)"
        :icon="navItem.icon"
        class="mb-3 mt-3"
      ></nav-menu-link>
    </v-list>
  </v-navigation-drawer>
</template>

<script>
// eslint-disable-next-line object-curly-newline
import {
  mdiHomeOutline,
  mdiAlphaTBoxOutline,
  mdiEyeOutline,
  mdiCreditCardOutline,
  mdiTable,
  mdiFileOutline,
  mdiFormSelect,
  mdiAccountCogOutline,
  mdiAccount,
  mdiLogout,
  mdiCashLock,
  mdiPartyPopper,
  mdiBellRingOutline,
  mdiKey,
} from '@mdi/js'
import NavMenuLink from './components/NavMenuLink.vue'

let navigationMenu = [
  {
    title: 'Donation Categories',
    route: { name: 'donation-types' },
    icon: mdiHomeOutline,
    requiredRole: ['admin'],
  },
  {
    title: 'Festivals',
    route: { name: 'festivals' },
    icon: mdiPartyPopper,
    requiredRole: ['admin'],
  },
  {
    title: 'Temples',
    route: { name: 'temples' },
    icon: mdiHomeOutline,
    requiredRole: ['admin'],
  },
  {
    title: 'Celebrations',
    route: {name: 'celebrations'},
    icon: mdiPartyPopper,
    requiredRole: ['admin', 'kothari'],
  },
  {
    title: 'Donations',
    route: { name: 'donations' },
    icon: mdiCashLock,
    requiredRole: ['admin', 'kothari', 'cashier'],
  },
  {
    title: 'Donors',
    route: { name: 'donors' },
    icon: mdiAccountCogOutline,
    requiredRole: ['admin', 'kothari'],
  },
  // {
  //   title: 'Reports',
  //   route: { name: 'reports' },
  //   icon: mdiFormSelect,
  //   requiredRole: ['admin'],
  // },
  {
    title: 'Change Password',
    route: { name: 'change-password' },
    icon: mdiKey,
    requiredRole: ['admin', 'kothari', 'cashier'],
  },
  {
    title: 'Logout',
    method: 'doLogout',
    icon: mdiLogout,
  },
];

export default {
  data() {
    return {
      navigationMenu,
      icons: {
        mdiHomeOutline,
        mdiAlphaTBoxOutline,
        mdiEyeOutline,
        mdiCreditCardOutline,
        mdiTable,
        mdiFileOutline,
        mdiFormSelect,
        mdiAccountCogOutline,
        mdiAccount,
        mdiLogout,
        mdiBellRingOutline,
      },
    }
  },
  props: {
    isDrawerOpen: {
      type: Boolean,
      default: null,
    },
  },
  components: {
    NavMenuLink,
  },
  computed: {
    user() {
      let user = this.$cookie.get('auth-user');
      return JSON.parse(user);
    }
  },
  created() {
    this.userType = localStorage.getItem('type');
  },
  methods: {
    handleClick(functionName) {
      if(functionName) {
        this[functionName]();
      }
    },
    async doLogout() {

      await axiosBackend.post('/logout');
      this.$cookie.delete('auth-user');
      this.$cookie.delete('auth-token');
      this.$store.commit('setLoginUser', null);
      this.$router.push('/login')
    }
  }
}
</script>

<style>
  .v-navigation-drawer {
    border-right: 1px solid #d2d2da;
  }
  .theme--dark .v-navigation-drawer {
    border-right: 1px solid #524e68;
  }
</style>

<style lang="scss" scoped>
.app-title {
  font-size: 1.25rem;
  font-weight: 700;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: 0.3px;
}

// ? Adjust this `translateX` value to keep logo in center when vertical nav menu is collapsed (Value depends on your logo)
.app-logo {
  transition: all 0.18s ease-in-out;
  .v-navigation-drawer--mini-variant & {
    transform: translateX(-4px);
  }
}

@include theme(app-navigation-menu) using ($material) {
  background-color: map-deep-get($material, 'background');
}

.app-navigation-menu {
  .v-list-item {
    &.vertical-nav-menu-link {
      ::v-deep .v-list-item__icon {
        .v-icon {
          transition: none !important;
        }
      }
    }
  }
}

// You can remove below style
// Upgrade Banner
.app-navigation-menu {
  .upgrade-banner {
    position: absolute;
    bottom: 13px;
    left: 50%;
    transform: translateX(-50%);
  }
}
</style>
