<template>
  <v-app>
    <vertical-nav-menu :is-drawer-open.sync="isDrawerOpen"></vertical-nav-menu>

    <div class="mx-6 mt-3">
      <v-app-bar-nav-icon class="d-block d-lg-none me-2" @click="isDrawerOpen = !isDrawerOpen"></v-app-bar-nav-icon>
    </div>

    <v-main>
<!--      <v-btn @click="switchTheme" fab elevation="2" right bottom fixed>-->
<!--        <v-icon>{{ $vuetify.theme.dark ? icons.mdiWeatherSunny : icons.mdiWeatherNight }}</v-icon>-->
<!--      </v-btn>-->
      <div class="app-content-container boxed-container pa-4 mt-6 mb-6">
        <slot></slot>
      </div>
    </v-main>
  </v-app>
</template>

<script>

import { mdiWeatherNight, mdiWeatherSunny } from '@mdi/js'
import VerticalNavMenu from './components/vertical-nav-menu/VerticalNavMenu.vue'

export default {
  data () {
    return {
      icons: {
        mdiWeatherNight,
        mdiWeatherSunny,
      },
      isDrawerOpen: null,
    }
  },
  components: {
    VerticalNavMenu,
  },
  methods: {
    switchTheme() {
      this.$vuetify.theme.dark = !this.$vuetify.theme.dark;
      this.$cookie.set('current-theme', this.$vuetify.theme.dark);
    }
  },
  mounted() {
    this.$vuetify.theme.dark = this.$cookie.get('current-theme') === 'true';
  },
}
</script>

<style lang="scss" scoped>
.v-app-bar ::v-deep {
  .v-toolbar__content {
    padding: 0;

    .app-bar-search {
      .v-input__slot {
        padding-left: 18px;
      }
    }
  }
}

.boxed-container {
  max-width: 1440px;
  margin-left: auto;
  margin-right: auto;
}
</style>
