import '@/styles/styles.scss';
import '@/plugins/vue-composition-api';

import Vue from 'vue';
Vue.config.productionTip = false;

const axios = require('axios');
window.axiosBackend = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  withCredentials: true,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json'
  }
});
window.axiosInstanceCSC = axios.create({
  baseURL: process.env.VUE_APP_API_URL,
  headers: {
    'X-Requested-With': 'XMLHttpRequest',
    'Content-Type': 'application/json'
  }
})

import VueTelInputVuetify from 'vue-tel-input-vuetify/lib';
import vuetify from '@/plugins/vuetify'
Vue.use(VueTelInputVuetify, {
  vuetify,
});

import VueCookie from 'vue-cookie';
Vue.use(VueCookie);

Vue.use(require('vue-moment'));

import App from './App.vue'
import router from './router'
import store from './store'

import VueSanctum from 'vue-sanctum';
Vue.use(VueSanctum, {
  axios: axiosBackend,
  store,
  fetchUserAfterLogin: true,
  routes: {
    login: '/otp/verify',
    me: '/me',
  }
});

new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')
